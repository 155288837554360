/* eslint-disable no-underscore-dangle */
class AppStorage {
  constructor(config) {
    if (AppStorage.instance) {
      return AppStorage.instance;
    }

    const options = Object.assign({}, AppStorage.DEFAULT_CONFIG, config);

    this.storage = options.storage;
    this.storageKey = options.storageKey;
    if (!this.storage.getItem(this.storageKey)) {
      this.storage.setItem(this.storageKey, '{}');
    }

    AppStorage.instance = this;

    return this;
  }

  static getInstance() {
    return AppStorage.instance;
  }

  get(item) {
    const storage = this.__getStorageObject();

    if (!item) {
      return storage;
    }

    return storage[`${item}`];
  }

  add(item, value) {
    if (typeof item !== 'string' || !value) {
      throw new TypeError('Item or value either invalid or missing.');
    }

    const storage = this.__getStorageObject();

    storage[`${item}`] = JSON.stringify(value);

    this.storage.setItem(this.storageKey, this.__prepareStorageObject(storage));
  }

  remove(item) {
    if (typeof item !== 'string') {
      throw new TypeError('Item either invalid or missing.');
    }

    const storage = this.__getStorageObject();

    if (`${item}` in storage) {
      delete storage[`${item}`];
    }

    this.storage.setItem(this.storageKey, this.__prepareStorageObject(storage));
  }

  clear() {
    this.storage.removeItem(this.storageKey);
  }

  destroy() {
    this.clear();
    AppStorage.instance = null;
  }

  __getStorageObject() {
    const storage = this.storage.getItem(this.storageKey);
    if (!storage) {
      return {};
    }

    return JSON.parse(storage);
  }

  // eslint-disable-next-line class-methods-use-this
  __prepareStorageObject(input) {
    if (typeof input !== 'object' || input === null) {
      throw new TypeError(`Cannot prepare storage since it is not an object`);
    }

    return JSON.stringify(input);
  }
}

const mockStorage = {
  getItem() {}, // noop
  setItem() {}, // noop
  removeItem() {}, // noop
};

AppStorage.DEFAULT_CONFIG = {
  storageKey: 'WEREMOTE_APP_STORAGE',
  storage: (typeof window !== 'undefined' && window.localStorage) || mockStorage,
};

AppStorage.instance = null;

export default AppStorage;
