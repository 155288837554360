import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cc from 'change-case';
import { Field } from 'formik';

const FieldTypes = {
  checkbox: 'checkbox',
  radio: 'radio',
};

const FormikOptions = ({ field, options, className }) => (
  <div className={`${className} formik-options`}>
    {options.map(t => {
      const kebab = cc.kebab(t);
      return (
        <Fragment key={kebab}>
          <Field
            type={FieldTypes[field.type] || FieldTypes.radio}
            className="hidden"
            name={field.name}
            value={t}
            id={kebab}
          />
          <label className="option-label" tabIndex="0" htmlFor={kebab}>
            {t}
          </label>
        </Fragment>
      );
    })}
  </div>
);

FormikOptions.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FormikOptions;
