import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from '../global/header';
import Footer from '../global/footer';
import HeroForm from '../hero-form';
import ReferralProgram from '../referral-program';
import AppStorage from '../../lib/Storage';

const storage = new AppStorage();

function LandingPageLayout({ showNavigation, children, referrer }) {
  const companyData = storage.get('WeRemote-company-data');
  let companyHandle = '';
  if (companyData) {
    const data = JSON.parse(companyData);
    companyHandle = data.handle;
  }
  const [handle, setHandle] = useState(companyHandle);

  return (
    <div className="flex flex-col font-mono min-h-screen relative">
      <Helmet meta={[{ charset: 'UTF-8' }]} />
      <div>
        <Header showNavigation={showNavigation} />
        <div className="flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-4 py-8 md:p-8 w-full">
          {handle ? (
            <ReferralProgram handle={handle} />
          ) : (
            <HeroForm referrer={referrer} onSuccess={setHandle} />
          )}
        </div>
        {/* <div className="relative -mt-12 lg:-mt-24">
          <img className="block align-middle" src={headerBG} alt="Wave forms" />
        </div> */}
      </div>

      <div className="flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-4 py-8 md:p-8 w-full">
        {children}
      </div>

      <Footer />

      <script
        id="mcjs"
        data-cookieconsent="statistics"
        src="https://chimpstatic.com/mcjs-connected/js/users/bb64d4f75cf09beb49c9a2918/464c8aeb9dbf315d4d76ff8a8.js"
      />
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="eb075372-4761-417f-91bb-cc65d12b6e5c"
        type="text/javascript"
        async
      />
    </div>
  );
}

LandingPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showNavigation: PropTypes.bool,
};

LandingPageLayout.defaultProps = {
  showNavigation: true,
};

export default LandingPageLayout;
