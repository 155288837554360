import * as Yup from 'yup';

// eslint-disable-next-line no-control-regex
const EMAIL_PATTERN = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

const CompanyHandleFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Too short (min. 4)')
    .required('Required'),
  email: Yup.string()
    .min(4, 'Too short (min. 4)')
    .max(200, 'Too long (max. 200)')
    .matches(EMAIL_PATTERN, 'Not an email')
    .required('Required'),
  handle: Yup.string()
    .min(4, 'Too short (min. 4)')
    .max(200, 'Too long (max. 200)')
    .matches(/^[a-zA-z0-9_]+$/i, 'No special characters')
    .required('Required'),
  remote: Yup.string().oneOf(['yes', 'no']),
});

export default CompanyHandleFormSchema;
