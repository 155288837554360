/* eslint-disable react/no-danger */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { graphql } from 'gatsby';
import LandingPageLayout from '../components/layouts/landing-page-layout';
import SEO from '../components/seo';

export const query = graphql`
  query GetLandingPageWithLocale($locale: String!) {
    contentfulHomepageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords {
        keywords
      }
      firstBlockHeading
      firstBlockContent {
        childMarkdownRemark {
          html
        }
      }
      secondBlockHeading
      secondBlockContent {
        childMarkdownRemark {
          html
        }
      }
      secondBlockImage {
        fluid {
          src
        }
        description
      }
      thirdBlockHeading
      thirdBlockContent {
        childMarkdownRemark {
          html
        }
      }
      thirdBlockImage {
        file {
          url
        }
        description
      }
    }
  }
`;

function LandingPage({ data, location }) {
  const { contentfulHomepageSettings: settings } = data;
  const referrerHandle = location.search.split('=')[1];
  return (
    <LandingPageLayout showNavigation={false} referrer={referrerHandle}>
      <SEO
        title={settings.title}
        keywords={settings.keywords.keywords.split(',')}
        description={settings.description.description}
      />

      <div className="flex flex-col max-w-6xl items-center m-auto text-lg">
        <h1 className="text-center font-normal text-4xl mb-4">About this initiative.</h1>
        <div className="leading-normal text-xl">
          <strong>Reserve your WeRemote company handle</strong> is an initiative to add both company
          and professional accounts to the platform. <br />
          <br />
          We're aware that proper and unified branding is important and this is why we're offering
          companies the chance to reserve their handle on the platform before we launch the feature.
          This allows you to make sure you have the proper brand presence everywhere and that nobody
          else gets to reserve your handle and use it instead of you.
        </div>
      </div>
    </LandingPageLayout>
  );
}

export default LandingPage;
