/* eslint-disable no-alert */
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import rocket from '../images/rocket.svg';
import CompanyHandleFormSchema from '../schemas/company-handle-form';
import FormikOptions from './options-formik';
import AppStorage from '../lib/Storage';

const storage = new AppStorage();
const CREATE_COMPANY_HANDLE = gql`
  mutation CreateCompanyHandle(
    $handle: String!
    $name: String!
    $email: String!
    $remote: String
    $referrer: String
  ) {
    addCompany(
      input: { handle: $handle, email: $email, name: $name, remote: $remote, referrer: $referrer }
    )
  }
`;

const HeroForm = ({ referrer, onSuccess }) => {
  const [createCompanyHandle] = useMutation(CREATE_COMPANY_HANDLE);
  return (
    <div className="hero--dark md:justify-between md:flex-row md:text-left border-b pb-4 border-gray-300">
      <div className="px-2 py-4 w-f text-2xl font-bold md:w-3/5">
        <h1 className="hero-heading font-normal">
          Reserve your company&apos;s handle before others do.
        </h1>
        <span className="text-xl md:text-2xl inline-block font-normal">
          Trust us, <strong>weremote.eu/acmecorp</strong> looks way better than{' '}
          <strong>weremote.eu/acmecorp-123</strong>.
        </span>

        <Formik
          initialValues={{
            name: '',
            email: '',
            handle: '',
            remote: '',
          }}
          validateOnBlur
          validationSchema={CompanyHandleFormSchema}
          onSubmit={async (values, actions) => {
            try {
              const result = await createCompanyHandle({
                variables: {
                  ...values,
                  referrer,
                },
              });
              const {
                data: { addCompany },
              } = result;
              if (!addCompany) {
                return alert(
                  'Cannot reserve handle (possible reasons): \n- Company handle already taken\n- Email address already used'
                );
              }
              actions.resetForm();
              storage.add('WeRemote-company-data', { handle: values.handle });
              return onSuccess(values.handle);
            } catch (e) {
              actions.resetForm();
              return alert('Something went wrong with your request.');
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="my-12  text-left w-full md:w-3/4 relative">
              <label
                className="uppercase tracking-wide text-gray-700 font-bold block mb-2 text-base"
                htmlFor="handle"
              >
                <span className="text-red-600">*</span>Company Handle&nbsp;
                <ErrorMessage
                  className="text-sm bg-red-100 text-red-700 rounded-default px-2 py-1 inline-block lowercase font-normal"
                  name="handle"
                  component="span"
                />
              </label>
              <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                <div className="flex -mr-px text-xl">
                  <span className="flex items-center leading-normal bg-blue-300 text-blue-700 rounded-default rounded-r-none px-4 whitespace-nowrap text-gray-700">
                    @
                  </span>
                </div>
                <Field
                  className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 appearance-none relative text-xl bg-blue-100 px-2 py-4 rounded-default rounded-l-none font-bold text-blue-900"
                  id="handle"
                  type="text"
                  name="handle"
                  placeholder="acme"
                />
              </div>

              <div className="mt-6">
                <label
                  className="uppercase tracking-wide text-gray-700 font-bold inline-block mb-2 text-base"
                  htmlFor="name"
                >
                  <span className="text-red-600">*</span>Company Name&nbsp;
                  <ErrorMessage
                    className="text-sm bg-red-100 text-red-700 rounded-default px-2 py-1 inline-block lowercase font-normal"
                    name="name"
                    component="span"
                  />
                </label>
                <Field
                  className="appearance-none block bg-blue-100 px-2 py-4 rounded-default text-xl font-bold text-blue-900 w-full"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="ACME Corp."
                />
              </div>
              <div className="mt-6">
                <label
                  className="uppercase tracking-wide text-gray-700 font-bold inline-block mb-2 text-base"
                  htmlFor="email"
                >
                  <span className="text-red-600">*</span>Business Email&nbsp;
                  <ErrorMessage
                    className="text-sm bg-red-100 text-red-700 rounded-default px-2 py-1 inline-block lowercase font-normal"
                    name="email"
                    component="span"
                  />
                </label>
                <Field
                  className="appearance-none block bg-blue-100 px-2 py-4 rounded-default text-xl font-bold text-blue-900 w-full"
                  id="email"
                  name="email"
                  type="text"
                  placeholder="jane@acme-corp.co"
                />
              </div>

              <div className="mt-6">
                <label
                  className="uppercase tracking-wide text-gray-700 font-bold inline-block mb-2 text-base"
                  htmlFor="remote"
                >
                  Are you already remote?
                </label>
                <FormikOptions
                  className="text-xl"
                  field={{
                    name: 'remote',
                    type: 'radio',
                  }}
                  options={['yes', 'no']}
                />
              </div>
              <button
                className="mt-10 w-full block cta"
                type="submit"
                id="submit-form"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending ⏳' : 'Reserve now'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="px-2 py-4 w-f text-2xl font-bold md:w-2/5 md:m-0 m-auto">
        <img src={rocket} alt="Rocket hero" />
      </div>
    </div>
  );
};

export default HeroForm;
