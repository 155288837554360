/* eslint-disable no-alert */
import React from 'react';
import rocket from '../images/rocket.svg';
import Share from './share';

const ReferralProgram = ({ handle }) => (
  <div className="hero--dark md:justify-between md:flex-row md:text-left border-b pb-8 border-gray-300">
    <div className="px-2 py-4 w-f text-2xl font-bold md:w-3/5">
      <h1 className="hero-heading font-normal">
        Congratulations,
        <br />
        <strong>@{handle}</strong> is now yours!
      </h1>
      <span className="text-xl md:text-2xl inline-block font-normal">
        Want a <strong>50% lifetime discount</strong> on all future purchases on the platform?
        <br /> <br />
        <strong>Get 5 other companies to sign up</strong>.<br />
        <br />
        Just click <strong>Copy</strong> and share your referral link with your friends and business
        partners.
      </span>
      <div className="my-12 text-left w-full md:w-3/4 relative">
        <label
          className="uppercase tracking-wide text-gray-700 font-bold block mb-2 text-base"
          htmlFor="handle"
        >
          Referral link
        </label>
        <div className="flex flex-wrap items-stretch w-full mb-4 relative">
          <input
            className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 appearance-none relative text-xl bg-blue-100 px-2 py-4 rounded-default rounded-l-none font-bold text-blue-900"
            id="handle"
            type="text"
            name="handle"
            defaultValue={`${process.env.SITE_URL}/reserve-company-handle?ref=${handle}`}
          />
          <div className="flex -mr-px text-xl">
            <button
              type="button"
              onClick={() => {
                const refUrl = document.querySelector('#handle');
                refUrl.select();
                document.execCommand('copy');
              }}
              className="flex bg-blue-700 text-blue-100 items-center leading-normal hover:bg-blue-300 hover:text-blue-700 rounded-default rounded-l-none px-4 whitespace-nowrap text-gray-700"
            >
              Copy
            </button>
          </div>
        </div>
        <span className="text-xl md:text-2xl mt-8 mb-4 inline-block font-normal">
          Or you can use the share buttons below.
        </span>
        <Share
          url={`${process.env.SITE_URL}/reserve-company-handle?ref=${handle}`}
          title="We just reserved our company handle on WeRemote.EU! You should too."
          description={`We just reserved our company handle on WeRemote.EU! Starting today, we're @${handle} on the jobs platform. `}
        />
      </div>
    </div>
    <div className="px-2 py-4 w-f text-2xl font-bold md:w-2/5 md:m-0 m-auto">
      <img src={rocket} alt="Rocket hero" />
    </div>
  </div>
);

export default ReferralProgram;
